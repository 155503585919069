body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Cantarell, Ubuntu, roboto, noto, arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body {
  width: 100vw;
  height: 100vh;
}

body {
  height: -webkit-fill-available;
  overflow: hidden;
  -webkit-user-select: none;
  user-select: none;
}

body * {
  box-sizing: border-box;
}

code {
  font-family: Menlo, Consolas, Monaco, Liberation Mono, Lucida Console, monospace;
}
